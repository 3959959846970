























































































































































































































import mixins from 'vue-typed-mixins'
import FlexibleBookingWeeklyCalendar from '@/calendesk/sections/section/shared/mixins/FlexibleBookingWeeklyCalendar'
import CBookingDatePicker from '@/calendesk/sections/section/booking/components/CBookingDatePicker.vue'
import CCalendarService from '@/components/CCalendarService.vue'
import CLocationSelect from '@/components/CLocationSelect.vue'
import CTimeZoneTextButton from '@/components/CTimeZoneTextButton.vue'

export default mixins(FlexibleBookingWeeklyCalendar).extend({
  name: 'FlexibleBooking1',
  components: {
    CTimeZoneTextButton,
    CLocationSelect,
    CCalendarService,
    CBookingDatePicker
  }
})
