var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticClass:"default-colors",class:{ 'default-colors-invert': _vm.invertColors },attrs:{"close-on-content-click":false,"max-width":320,"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pointer d-flex align-center primary-link-color"},'div',Object.assign({}, attrs, tooltipAttrs),false),Object.assign({}, on, tooltipOn)),[_c('v-icon',{staticClass:"ml-2 mr-1",attrs:{"small":"","color":"primary"}},[_vm._v("$earth")]),_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.getFullTimeZoneText(_vm.timeZone)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$trans('time_zone')))])])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-title',{staticClass:"default-colors",class:{ 'default-colors-invert': _vm.invertColors }},[_vm._v(" "+_vm._s(_vm.$trans('time_zone'))+" "),_c('v-spacer'),_c('v-switch',{staticClass:"ml-2",attrs:{"dense":"","false-value":"12h","true-value":"24h"},on:{"change":_vm.timeNotationOnChange},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary","small":""}},[_vm._v("$clock")]),_c('span',{staticClass:"color-1-base"},[_vm._v(_vm._s(_vm.timeNotation === '12h' ? 'am/pm' : '24h'))])]},proxy:true}]),model:{value:(_vm.switchTimeNotation),callback:function ($$v) {_vm.switchTimeNotation=$$v},expression:"switchTimeNotation"}})],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',{staticClass:"default-colors",class:{ 'default-colors-invert': _vm.invertColors },attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$trans('search'),"dense":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"mt-0",attrs:{"cols":"12"}},[_c('v-list',{ref:"timeZoneList",staticClass:"overflow-auto pt-0 mt-0",attrs:{"max-height":"300"}},_vm._l((_vm.filteredTimeZones),function(item){return _c('v-list-item',{key:item.text,staticClass:"default-colors",class:{
                  'default-colors-invert': _vm.invertColors,
                   'v-list-item--active': item.text === _vm.timeZone
                },attrs:{"color":"primary"},on:{"click":function($event){return _vm.selectTimeZone(item)}}},[_c('v-list-item-content',{staticClass:"default-colors"},[_vm._v(" "+_vm._s(_vm.getFullTimeZoneText(item.text))+" ")])],1)}),1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"default-colors",class:{ 'default-colors-invert': _vm.invertColors }},[_c('v-btn',{attrs:{"block":"","text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" "+_vm._s(_vm.$trans('close'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }