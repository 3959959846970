





















import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'
import LocationItem from '@/components/LocationItem.vue'
export default mixins(DraftElement).extend({
  name: 'CLocationSelect',
  components: { LocationItem },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    // TODO: rename to serviceLocations
    locations: {
      type: Array,
      default: () => Array<ServiceLocation>()
    },
    value: {
      type: ServiceLocation,
      default: null
    },
    alwaysSelected: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedLocation: null as null|ServiceLocation
    }
  },
  computed: {
    firstLocation (): ServiceLocation|null {
      return this.locations && this.locations.length > 0 ? this.locations[0] as ServiceLocation : null
    }
  },
  watch: {
    alwaysSelected (value) {
      if (!this.selectedLocation && this.locations && this.locations.length > 0 && (value || this.locations.length === 1)) {
        this.selectedLocation = this.locations[0] as ServiceLocation
      }
    },
    value (val) {
      if (this.alwaysSelected && !val && this.locations && this.locations.length > 0) {
        this.selectedLocation = this.locations[0] as ServiceLocation
      } else {
        this.selectedLocation = val
      }
    },
    locations () {
      this.selectLocationIfEmpty()
    },
    selectedLocation (location: ServiceLocation | null) {
      this.$emit('change', location)
      this.$emit('input', location)
    }
  },
  created () {
    this.selectLocationIfEmpty()
  },
  methods: {
    selectLocationIfEmpty () {
      if (!this.selectedLocation && (this.alwaysSelected || (this.locations && this.locations.length === 1))) {
        this.selectedLocation = (this.locations && this.locations.length > 0) ? (this.locations[0] as ServiceLocation) : null
      }
    }
  }
})
