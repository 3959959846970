import mixins from 'vue-typed-mixins'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import timeToNumber from '@/calendesk/filters/timeToNumber'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import Service from '@/calendesk/models/DTO/Response/Service'
import BookingEvent from '@/calendesk/models/BookingEvent'
import FlexibleBooking from '@/calendesk/sections/section/shared/mixins/FlexibleBooking'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import ServiceLocation from '@/calendesk/models/DTO/Response/ServiceLocation'

export default mixins(FlexibleBooking).extend({
  data: () => ({
    timeSpots: null as Record<string, any> | null,
    firstTime: '08:00',
    intervalCount: 24,
    intervalMinutes: 60,
    events: [] as Array<any>,
    cart: [] as Array<any>,
    lastReloadData: null as any
  }),
  watch: {
    customerTimeZone (newTz: string | null, oldTz: string | null) {
      if (newTz !== oldTz) {
        this.reloadEvents(this.lastReloadData)
      }
    },
    selectedLocation (newLocation: ServiceLocation | null, oldLocation: ServiceLocation | null) {
      if (!newLocation || !oldLocation || newLocation.id !== oldLocation.id) {
        this.reloadEvents(this.lastReloadData)
      }
    },
    selectedSelectableService () {
      this.checkLocationSelection()
      this.selectAllEmployees()
      this.reloadEvents(this.lastReloadData)
    },
    shouldReload (reload) {
      if (reload) {
        this.reloadEvents(this.lastReloadData)
        this.setReloadAllBookings(false)
        this.cart = []
        this.checkLocationSelection()
      }
    },
    closedDialogData (closedDialogData: any) {
      if (closedDialogData) {
        const startTime = closedDialogData.data.eventParsed.start.time
        const endTime = closedDialogData.data.eventParsed.end.time
        const startDate = closedDialogData.data.eventParsed.start.date
        const endDate = closedDialogData.data.eventParsed.end.date
        const service = closedDialogData.data.event.service
        const duration = closedDialogData.data.event.duration
        const serviceType = closedDialogData.data.event.serviceType
        const employee = closedDialogData.employee
        const location = closedDialogData.location
        this.removeFromCart(startTime, startDate, service, employee)

        if (closedDialogData.addToCart) {
          this.cart.push({
            startTime,
            endTime,
            startDate,
            endDate,
            duration,
            service,
            serviceType,
            employee,
            location
          })
        }

        this.generateEvents()
      }
    }
  },
  computed: {
    getType (): string {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'week'
      }

      return 'day'
    },
    datesRange (): string | null {
      if (this.lastReloadData) {
        const start = this.$moment(this.lastReloadData.start.date, DateTimeFormats.FULL).format('Do MMM')
        const end = this.$moment(this.lastReloadData.end.date, DateTimeFormats.FULL).format('Do MMM')

        return `${start} - ${end}`
      }

      return null
    }
  },
  mounted () {
    this.selectedDate = this.$moment().format(DateTimeFormats.FULL)
    this.setupCalendar()
  },
  methods: {
    checkLocationSelection () {
      if (this.locationAlwaysSelected &&
        this.selectedSelectableService &&
        this.selectedSelectableService.service &&
        this.selectedSelectableService?.service.locations &&
        this.selectedSelectableService?.service.locations.length > 0) {
        this.selectedLocation = this.selectedSelectableService?.service.locations[0]
      } else {
        this.selectedLocation = null
      }
    },
    isInCart (startTime: string, startDate: string, service: Service, employee: Employee | null): boolean {
      const item = this.cart.find(
        (item: any) =>
          item.startTime === startTime &&
          item.startDate === startDate &&
          item.service.id === service.id &&
          item.employee.id === employee?.id
      )
      return !!item
    },
    removeFromCart (startTime: string, startDate: string, service: Service, employee: Employee): any {
      this.cart = this.cart.filter(
        (item: any) =>
          !(item.startTime === startTime &&
          item.startDate === startDate &&
          item.service.id === service.id &&
            item.employee.id === employee.id)
      )
    },
    reloadEvents (data?: any) {
      this.lastReloadData = data

      if (this.selectedSelectableService && this.selectedDate) {
        this.isFetchingTimeSlots = true
        const requestedStartDate = this.lastReloadData && this.lastReloadData.start && (this.$moment(this.lastReloadData.start.date, DateTimeFormats.FULL).isAfter())
          ? this.lastReloadData.start.date
          : this.$moment().format(DateTimeFormats.FULL)

        this.fetchAvailableBookingSlots({
          number_of_days: 7,
          service_id: this.selectedSelectableService.service.id,
          start_date: requestedStartDate,
          service_type_id: this.selectedSelectableService.serviceType?.id || null,
          customer_time_zone: this.customerTimeZone,
          location_id: this.selectedLocation?.locationId || null
        }).then((response: Record<string, any>) => {
          this.timeSpots = response
          this.generateEvents()
        }).catch((error) => {
          this.$log.info('fetchAvailableBookingSlots@catch', error)
        }).finally(() => {
          this.isFetchingTimeSlots = false
        })
      }
    },
    generateEvents () {
      const events: Array<any> = []

      if (this.timeSpots && this.selectedSelectableService) {
        for (const employeeId in this.timeSpots) {
          if (this.selectedEmployeeIds.includes(parseInt(employeeId))) {
            for (const date in this.timeSpots[employeeId]) {
              const employee = this.employeeById(parseInt(employeeId))
              for (const hourIndex in this.timeSpots[employeeId][date]) {
                const time = this.timeSpots[employeeId][date][hourIndex]
                const duration = this.selectedSelectableService.serviceType ? this.selectedSelectableService.serviceType.duration : this.selectedSelectableService.service.duration
                const price = this.selectedSelectableService.serviceType ? this.selectedSelectableService.serviceType.price : this.selectedSelectableService.service.price
                const start = `${date}T${time}:00`
                const end = this.$moment(start).add(duration, 'minutes').format(DateTimeFormats.FULL + 'T' + DateTimeFormats.TIME)
                const serviceId = this.selectedSelectableService.service.id
                const inCart = this.isInCart(time, date, this.selectedSelectableService.service, employee)

                events.push({
                  inCart,
                  employeeName: employee?.getFullName(),
                  employeeId: employee?.id,
                  serviceName: this.selectedSelectableService.service.name,
                  serviceId: serviceId,
                  serviceTypeId: this.selectedSelectableService.serviceType ? this.selectedSelectableService.serviceType.id : null,
                  duration,
                  price,
                  start,
                  end,
                  color: inCart ? 'success' : this.backgroundColor1,
                  service: this.selectedSelectableService.service,
                  serviceType: this.selectedSelectableService.serviceType,
                  employee,
                  customerTimeZone: this.customerTimeZone,
                  location: this.selectedLocation
                })
              }
            }
          }
        }
      }

      this.events = events
    },
    eventClickHandler (data: any) {
      this.openDialog(new Dialog(
        true,
        DialogTypes.BOOKING_DIALOG_1,
        null,
        DialogSize.LARGE,
        false,
        'center',
        {
          event: data.event,
          eventParsed: data.eventParsed,
          configurationData: this.data
        })
      )
    },
    bookFromCartHandler () {
      const events: Array<BookingEvent> = []

      this.cart.forEach((item) => {
        events.push(new BookingEvent(
          item.service,
          item.employee,
          item.serviceType,
          item.startDate,
          item.startTime,
          item.endDate,
          item.endTime,
          null,
          this.customerTimeZone,
          false,
          item.location))
      })

      this.openDialog(new Dialog(
        true,
        DialogTypes.BOOKING_FORM_DIALOG,
        null,
        DialogSize.MIDDLE,
        false,
        'center',
        {
          events,
          configurationData: this.data
        },
        true))
    },
    setupCalendar () {
      // Get it from configuration of the section
      const startCalendarTime = this.data.configuration.wb_start_calendar_time__time__
      const endCalendarTime = this.data.configuration.wb_end_calendar_time__time__
      const intervalMinutes = this.data.configuration.wb_interval_minutes__number__

      const startTimeNumber = timeToNumber(startCalendarTime)
      const endTimeNumber = timeToNumber(endCalendarTime)

      this.firstTime = startCalendarTime
      this.intervalCount = (endTimeNumber - startTimeNumber) / intervalMinutes
      this.intervalMinutes = intervalMinutes

      this.selectAllEmployees()
      this.selectFirstServiceAsDefault()
    }
  }
})
