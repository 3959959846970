
















































































import timeZones from '@/calendesk/lib/timeZones.ts'
import { mapActions, mapGetters } from 'vuex'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'

export default mixins(DraftElement).extend({
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    invertColors: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    timeZones: [] as any[],
    timeZone: null as string | null,
    switchTimeNotation: null as string | null,
    menu: false,
    search: ''
  }),
  computed: {
    ...mapGetters({
      timeNotation: 'setup/getTimeNotation'
    }),
    filteredTimeZones (): any[] {
      if (!this.search) return this.timeZones
      return this.timeZones.filter(tz =>
        tz.text.toLowerCase().includes(this.search.toLowerCase())
      )
    }
  },
  watch: {
    value (val) {
      this.timeZone = val
    },
    timeZone (val) {
      this.$emit('input', val)
    },
    timeNotation () {
      this.switchTimeNotation = this.timeNotation
    },
    menu (val) {
      if (val) {
        setTimeout(() => {
          this.scrollToSelectedTimeZone()
        }, 500)
      }
    }
  },
  created () {
    this.timeZone = this.value
    this.switchTimeNotation = this.timeNotation
    this.reloadTimeZones()
  },
  methods: {
    ...mapActions({
      setTimeNotation: 'setup/setTimeNotation'
    }),
    scrollToSelectedTimeZone () {
      if (this.$refs.timeZoneList) {
        const container = (this.$refs.timeZoneList as any).$el
        const selectedItem = container.querySelector('.v-list-item--active')
        if (selectedItem) {
          container.scrollTop = selectedItem.offsetTop - container.clientHeight / 2
        }
      }
    },
    reloadTimeZones () {
      this.timeZones = timeZones.map((timeZone: string) => {
        return {
          text: timeZone
        }
      })
    },
    getTimeZoneTime (timeZone: string): string {
      const momentTz = this.$moment().tz(timeZone)

      if (momentTz) {
        let hour = momentTz.format('HH:mm')
        hour = (this.$options.filters as any).timeNotation(hour)

        return hour
      }

      return '-'
    },
    getFullTimeZoneText (timeZone: string): string {
      return `${timeZone} (${this.getTimeZoneTime(timeZone)})`
    },
    selectTimeZone (timeZone: any) {
      this.timeZone = timeZone.text
      this.menu = false
    },
    timeNotationOnChange (timeNotation: string) {
      this.setTimeNotation(timeNotation)
      this.reloadTimeZones()
    }
  }
})
