












































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
export default mixins(DraftElement).extend({
  name: 'CBookingDatePicker',
  props: {
    date: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    events: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      menu: false,
      selectedDate: null as string | null,
      minDate: null as string | null,
      pickerDate: null as string | null
    }
  },
  watch: {
    selectedDate () {
      this.$emit('change', this.selectedDate)
    },
    pickerDate () {
      const val = this.$moment(this.pickerDate, 'YYYY-MM')
        .date(this.$moment(this.selectedDate, DateTimeFormats.FULL).date())
        .format(DateTimeFormats.FULL)
      this.selectedDate = val
    },
    date (val) {
      this.selectedDate = val
      this.pickerDate = val
    }
  },
  mounted () {
    this.selectedDate = this.$moment().format(DateTimeFormats.FULL)
    this.pickerDate = this.$moment().format(DateTimeFormats.FULL)
    this.minDate = this.$moment().format(DateTimeFormats.FULL)
  }
})
